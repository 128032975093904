import { ResponseType } from 'axios';
import { msalInstance } from '../../..';
import { cleanUndefinedToNull, getPureSubDomainOrHint, isNotNullAndUndefined } from '../../../RAFComponents/helpers/utils';
import { ContentType, RAFHeaderNames } from "./../../../RAFComponents/helpers/Constants";
import axios from './../../services/axios/axios';
import * as actionTypes from './actionTypes';
import * as errorHandlerActions from './errorHandlerActions';

//var processQuery = (url: string, data: any): string => {
//    if (data) {
//        return `${url}?${jsonToUrl(data)}`;
//    }
//    return url;
//};

/*
const getDataSuccess = (data) => {
    return {
        type: actionTypes.GET_DATA_SUCCESS,
        data: data
    }
}

export const getData = (url, data, props, contentType?: string) => {
    return (dispatch) => {
        axios.get(url, {
            data: data,
            headers: {
                'Content-Type': (contentType !== null && contentType !== undefined && contentType !== '') ? contentType : ContentType.applicationFormUrlEncoded
            }
        })
            .then(response => {
                dispatch(getDataSuccess(response.data));
            })
            .catch(error => {
                dispatch(errorHandlerActions.handleHTTPError(error, props));
            })
    }
}


const putDataSuccess = (response) => {
    return {
        type: actionTypes.PUT_DATA_SUCCESS,
        response: response
    }
}

export const putData = (url, data, props, contentType?: string) => {
    return (dispatch) => {
        axios.put(url, data, {
            headers: {
                'Content-Type': (contentType !== null && contentType !== undefined && contentType !== '') ? contentType : ContentType.applicationFormUrlEncoded
            }
        })
            .then(response => {
                dispatch(putDataSuccess(response));
            })
            .catch(error => {
                dispatch(errorHandlerActions.handleHTTPError(error, props));
            })
    }
}


const deleteDataSuccess = (response) => {
    return {
        type: actionTypes.DELETE_DATA_SUCCESS,
        response: response
    }
}

export const deleteData = (url, data, props, contentType?: string) => {
    return (dispatch) => {
        axios.delete(processQuery(url, data), {
            headers: {
                'Content-Type': (contentType !== null && contentType !== undefined && contentType !== '') ? contentType : ContentType.applicationFormUrlEncoded
            }
        })
            .then(response => {
                dispatch(deleteDataSuccess(response));
            })
            .catch(error => {
                dispatch(errorHandlerActions.handleHTTPError(error, props));
            })
    }
}

export const closeSuccessModal = (props, url) => {
    return {
        type: actionTypes.CLOSE_SUCCESS_MODAL,
        props: props,
        url: url
    }
}


export const postDataAndCallBack = (url, data, props, contentType?: string, callBack?: Function) => {
    axios.post(url,
        data,
        {
            headers: {
                'Content-Type': (contentType !== null && contentType !== undefined && contentType !== '') ? contentType : ContentType.applicationFormUrlEncoded
            }
        })
        .then(response => {
            callBack(response.data);
        })
        .catch(error => {
        })
}

*/

const postDataSuccess = (response) => {
  return {
    type: actionTypes.POST_DATA_SUCCESS,
    response: response,
  };
};

export const postData = (url, data, props, contentType?: string) => {
  return (dispatch) => {
    axios
      .post(url, data, {
        headers: {
          "Content-Type":
            contentType !== null &&
              contentType !== undefined &&
              contentType !== ""
              ? contentType
              : ContentType.applicationFormUrlEncoded,
        },
      })
      .then((response) => {
        dispatch(postDataSuccess(response.data));
      })
      .catch((error) => {
        dispatch(errorHandlerActions.handleHTTPError(error, props));
      });
  };
};

export const getHeaders = (
  accessToken?: string,
  currentBusinessUnitId?: string,
  contentType?: string,
  cache?: boolean
) => {
  return {
    "Content-Type":
      contentType !== null && contentType !== undefined && contentType !== ""
        ? contentType
        : ContentType.applicationFormUrlEncoded,
    cache: cache,
    //'Authorization': `Bearer ${accessToken}`,
    //'businessUnitUID': currentBusinessUnitId,

    [RAFHeaderNames.Authorization]: `Bearer ${accessToken}`,
    [RAFHeaderNames.BusinessUnitUID]: currentBusinessUnitId,
    [RAFHeaderNames.Domain]: getPureSubDomainOrHint(),
  };
};

export function postDataAndGetResponse(
  url,
  data,
  props,
  contentType?: string,
  cache?: boolean,
  responseType?: ResponseType,
  convertUndefinedToNull?: boolean
) {

  //return msalInstance.getADToken().then((accessToken) => {
  axios.defaults.headers["Content-Type"] = contentType !== null && contentType !== undefined && contentType !== ""
    ? contentType
    : ContentType.applicationFormUrlEncoded;

  return axios
    .post(
      url,
      isNotNullAndUndefined(convertUndefinedToNull) &&
        convertUndefinedToNull === true
        ? cleanUndefinedToNull(data)
        : data,
      {
        withCredentials: true,
        // headers: getHeaders(
        //   accessToken,
        //   msalInstance.currentBusinessUnitId,
        //   contentType,
        //   cache
        // ),
        responseType: responseType,
      }
    )
    .then((response) => response)
    .catch((error) => error);
  //});
}

export function postDataAnonymous(
  url,
  data,
  props,
  contentType?: string,
  cache?: boolean,
  responseType?: ResponseType,
  convertUndefinedToNull?: boolean
) {
  return axios
    .post(
      url,
      isNotNullAndUndefined(convertUndefinedToNull) &&
        convertUndefinedToNull === true
        ? cleanUndefinedToNull(data)
        : data,
      {
        withCredentials: true,
        headers: getHeaders(
          null,
          null,
          contentType,
          cache),
        responseType: responseType,
      }
    )
    .then((response) => response)
    .catch((error) => error);
}

export function getData(
  url,
  data,
  props,
  contentType?: string,
  cache?: boolean,
  responseType?: ResponseType
) {
  axios.defaults.headers["Content-Type"] = contentType !== null && contentType !== undefined && contentType !== ""
    ? contentType
    : ContentType.applicationFormUrlEncoded;

  //return msalInstance.getADToken().then((accessToken) => {

  return axios
    .get(url, {
      data: data,
      withCredentials: true,
      // headers: getHeaders(
      //   accessToken,
      //   msalInstance.currentBusinessUnitId,
      //   contentType,
      //   cache
      // ),
      responseType: responseType,
    })
    .then((response2) => response2)
    .catch((error) => error);
  //});
}

export function getDataAnonymous(
  url,
  data,
  props,
  contentType?: string,
  cache?: boolean,
  responseType?: ResponseType
) {

  return axios
    .get(url, {
      data: data,
      withCredentials: true,
      headers: getHeaders(
        null,
        null,
        contentType,
        cache
      ),
      responseType: responseType,
    })
    .then((response2) => response2)
    .catch((error) => error);
}
