import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Logout = ({ }) => {
    const accountType = useParams().accountType;
    console.log('accountType', accountType, window.location);
    useEffect(() => {
        if (accountType === 'microsoft') {
            const logoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
            window.location.href = logoutUrl;
        } else
        //if (accountType === 'local') 
        {
            // Call your server to clear the local session
            window.location.href = `${window.location.origin}`;
        }
    }, [accountType]);

    return (
        <div>
            <h1>Logging out...</h1>
        </div>
    );

    // return (
    //     <>


    //         <div className="h-100">
    //             <div className="h-100 content panel p-0">
    //                 <div className="h-100 d-flex justify-content-lg-end justify-content-center">
    //                     <div className="right-section d-none d-lg-block min-vh-100">
    //                         <div className="h-100 position-relative">
    //                             <Suspense fallback={<SkyLoadingPanel loadingText="Loading..." />}>
    //                                 <LoginSlider />
    //                             </Suspense>
    //                         </div>
    //                     </div>
    //                     <div className="left-section bg-white">
    //                         <div className="loginContainer overflow-auto d-flex align-items-center flex-column h-100 customScrollBar">
    //                             <div className="w-100 formContainer d-flex flex-column align-items-center justify-content-center">
    //                                 <div className="text-center">
    //                                     <img src={clientLogo} alt="Product Logo" className="clientlogoImage mb-3" />
    //                                     <div className="d-flex justify-content-center align-items-center pb-2">
    //                                         <h3>Welcome to Skytrust Next Generation</h3>
    //                                     </div>
    //                                     <div className="d-flex justify-content-center align-items-center pb-2">
    //                                         <h5>
    //                                             To access Skytrust environment, login with your User
    //                                             Name
    //                                         </h5>
    //                                     </div>
    //                                     {isAuthenticated && <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={logoutClick}>Logout</ButtonComponent>}
    //                                     {(inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) &&
    //                                         // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    //                                         <ButtonComponent type="button" isPrimary cssClass='w-75' onClick={loginClick}>Login</ButtonComponent>
    //                                     }
    //                                     <div id="redirectDiv" className="hidden"></div>
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     </div>

    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // );
};

export default Logout;