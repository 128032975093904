import { propertyOf } from '../../../RAFComponents/helpers/utils';
import { RAFEntityBase } from './../Common/RAFEntityBase';

export class RelatedTo {
    UID?: string;
    Type?: string;
    Value?: string;
}

export class LookUpRow extends RAFEntityBase {
    UID?: string;
    //UID?: string;
    Value?: string;


    getIdField?(): string {
        return propertyOf<LookUpRow>("UID");
    }
    getNameField?(): string {
        return propertyOf<LookUpRow>("Value");
    }
    getListUrl?(): string {
        return "Email/List";
    }
}

