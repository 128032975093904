export enum RAFDataType {
    Text = 'text',
    Multiline = 'multiline',
    Number = 'number',
    Double = 'double',
    Dropdown = 'dropdown',
    Date = 'date',
    Lookup = 'lookup',
    Boolean = 'boolean',
    //Form = 'form',
    Form = 'subform',
    RadioButton = 'radiobutton',
    MultiSelect = 'multiselect ',
    DataList = 'datalist ',

    DateTime = 'datetime',
    Password = 'password',
    AutoNumber = 'autonumber',
    Formula = 'formula',
    Image = 'image',
    Video = 'video',
    RichText = 'richtexteditor',
    Heading = 'heading',
    Signature = 'signature',
    Module = 'module',
    Component = 'component',
    FormTemplate = 'formtemplate',
    Email = 'email',
    SMS = 'sms',
    EmbedForm = 'embedform',
    LinkForm = 'linkform',
    Address = 'address',
    Phone = 'phone',
    StarRating = 'StarRating',
    Page = 'page',
    Panel = 'panel',
    Section = 'section',
    Column = 'column',
    Paragraph = 'paragraph',
    Attachment = 'attachment',
    ActionBar = 'actionbar',
    RelatedSection = 'relatedSection',
    Question = 'question',
    DynamicSubForm = 'dynamicsubform',
    SubForm = 'subform',
    DynamicForm = 'dynamicform',
    Location = 'gpslocation',
    PrimaryForm = 'primaryform',
    RadioGroup = 'radiogroup',
    Comment = 'comment',
    TagBox = 'tagbox',
    Checkbox = 'checkbox',
    Rating = 'rating',
    Currency = 'Currency',
    File='file',
    Json = 'json'
}

export enum RAFDataTypeDisplayName {
    Text = 'Text',
    Multiline = 'TextArea',
    Number = 'Number',
    Dropdown = 'Dropdown',
    Date = 'Date',
    Lookup = 'Lookup',
    Boolean = 'Boolean',
    Form = 'Form',
    RadioButton = 'Single Choice',
    MultiSelect = 'Multi Choice ',
    DataList = 'Datalist ',

    DateTime = 'DateTime',
    Password = 'Password',
    AutoNumber = 'Autonumber',
    Formula = 'Formula',
    Image = 'Image',
    Video = 'Video',
    RichText = 'Richtexteditor',
    Heading = 'Heading',
    Signature = 'Signature',
    Module = 'Module',
    Component = 'Component',
    FormTemplate = 'Form Template',
    Email = 'Email',
    SMS = 'Sms',
    EmbedForm = 'Embed form',
    LinkForm = 'Link form',
    Address = 'Address',
    Phone = 'Phone',
    StarRating = 'Star Rating',
    Page = 'Page',
    Panel = 'Panel',
    Section = 'Section',
    Column = 'Column',
    Paragraph = 'Paragraph',
    Attachment = 'File'
}

export enum RAFUIType {
    RadioButton = 'radiobutton',
    CheckboxList = 'checkBoxList',
    //Dropdown = 'dropdown',
    //ColorType = 'Color type',
    //Normal = 'Normal',
    SimpleDropdown = 'dropdown',
    ColoredDropdown = 'coloreddropdown',
    ToggleButton = 'togglebutton',
    Singleline = 'singleline',
    Multiline = 'multiline',
    HtmlEditor = 'htmleditor',
    RRScheduler = 'rrscheduler',
    RRule = 'rrule',
    StarRating = 'starrating',
    Address = 'address',
    ComboBox = 'combobox',
    RelatedForm = 'relatedForm',
    AddOnly = 'add_only',
    SelectOnly = 'select_only',
    SelectAndAdd = 'select_and_add',
    Autosuggestion = 'autosuggestion',
    SmileyToggle = 'smileyToggle',
    DateOnly = 'date_only',
    TimeOnly = 'time_only',
    DateTime = 'date_time',
    RatingWithLabel = 'rating_with_label',
    Rating = 'rating',
    SentimentToggle = 'sentiment_toggle',
    SentimentToggleWithLabel = 'sentiment_toggle_with_label',
    DynamicForm = 'dynamicform',
    PrimaryForm = 'primaryform',
}


export enum CheckBoxUIType {
    Default = 'default',
    Switch = 'switch',
    EyeToggle = 'eyeToggle',
    CheckCircle = 'checkCircle',
}