import { RAFCustomFilter } from '../../RAFViewPanels/RAFFilterColumn/RAFCustomFilter';
import { RAFEntityBase } from './../../models/Common/RAFEntityBase';

export class ViewSelect {
    SelectColumns?: string[];
    DataColumns?: string[];
}

export class SortBy {
    Field?: string;
    Descending?: boolean;
}

export class GroupByJM {
    Field?: string;
    AggregateFunction?: string;
}

export class ParamField {
    Field?: string;
    Operator?: string;
    ShowAsParam?: boolean;
}

export class ViewSettings {
    IsPrivate?: boolean;
    ParamFields?: ParamField[];
    ShowInList?: boolean;
    MobileColumnCount?: number;
}

export class RAFViewRow extends RAFEntityBase {
    //Id?: number;
    UID?: string;
    ViewName?: string;
    DisplayName?: string;
    SystemFilterJson?: RAFCustomFilter;
    FilterJson?: RAFCustomFilter;
    OrderByJson?: SortBy[];
    GroupByJson?: GroupByJM[];
    SettingsJson?: ViewSettings;
    Isdefault?: boolean;
    EntityUID?: string;
    Entity?: string;
    ParentUID?: string;
    ViewType?: string;
    SelectColumnjson?: ViewSelect;
    IsSystem?: boolean;
    ParentEntityUID?: string;
    ResultCount?: number;

    PermissionName?: string;

    ModifiedDate?: Date;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedBy?: string;
    ModifiedByUID?: string;
}