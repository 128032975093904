
export interface RAFEntityBaseInterface {
    getClassName?(): string;
    getIdField?(): string;
    getListUrl?(): string;
    getSaveUrl?(): string;
    getGroupHeaderField?(): string;
    getRelatedListUrl?(): string;
    //isOptionCreatable?(): boolean;
    isMulti?(): boolean;
}

export class RAFEntityBase implements RAFEntityBaseInterface {
    getClassName?() {
        const name = this.constructor.name;
        return name.substring(0, name.lastIndexOf("Row"));
        // OR return (this as any).constructor.name;
    }
    getIdField?(): string {
        return "";
    }
    getGroupHeaderField?(): string {
        return "";
    }
    getListUrl?(): string {
        return "";
    }
    getSaveUrl?(): string {
        return "";
    }
    getGroupByUrl?(): string {
        return "";
    }
    //isOptionCreatable?(): boolean {
    //    return false;
    //}
    isMulti?(): boolean {
        return false;
    }
    getRelatedListUrl?(): string {
        return "";
    }
}
