import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { Constants } from "./../../../RAFComponents/helpers/Constants";
import { cache } from './cacheHandler';

const instance = axios.create({
    baseURL: Constants.baseAPIUrl,
    headers: {
        //headerType: 'example header type'
        // "Content-Type": ContentType.applicationJson,
    }, withCredentials: true,
});

/*
const whiteList = ['auth'];

function isURLInWhiteList(url: string) {
    return whiteList.includes(url.split('/')[1]);
}
*/

function responseHandler(response: AxiosResponse<any>): AxiosResponse<any> {
    // eslint-disable-next-line no-constant-condition
    const headerCache: boolean = (response.config.headers['cache'] !== null && response.config.headers['cache'] === 'true' ? true : false);
    //if (response.config.method === 'GET' || 'get') {
    if (headerCache) {
        //if (response.config.url && !isURLInWhiteList(response.config.url)) {
        if (response.config.url) {
            cache.store(response.config.url, JSON.stringify(response.data));
        }
    }
    return response;
}

function errorHandler(error: any) {
    //if (error.headers.cached === true) {
    if (error.headers != null && error.headers['cached'] === true) {
        return Promise.resolve(error);
    }
    return Promise.reject(error);
}

function requestHandler(request: InternalAxiosRequestConfig) {
    // eslint-disable-next-line no-constant-condition
    const headerCache: boolean = (request.headers['cache'] !== null && request.headers['cache'] === 'true' ? true : false);
    //if (request.method === 'GET' || 'get') {
    if (headerCache) {
        const checkIsValidResponse = cache.isValid(request.url || '');
        if (checkIsValidResponse.isValid) {
            //request.headers.cached = true;
            request.data = JSON.parse(checkIsValidResponse.value || '{}');
            return Promise.reject(request);
        }
    }
    return request;
}

instance.interceptors.request.use((request) => requestHandler(request));
instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
);

export default instance;