import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from '../../services/axios/axios';
//import axios from 'axios';
import { AzureAdB2C } from '../../../RAFAzure/AuthModule';
import { RAFHeaderNames } from '../../helpers/Constants';
import { getPureSubDomainOrHint, isNotNullAndUndefined } from '../../helpers/utils';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { hideProgress, showProgress } from '../../helpers/AppHelper';

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    /* eslint-disable no-param-reassign */
    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }
        let progressDiv = showProgress('body');
        const response = await instance.acquireTokenSilent({
            scopes: [AzureAdB2C.AcquireTokenScope],
            account: account
        });
        hideProgress(progressDiv);
        // .catch(error => {
        //     console.log('error on getting menu', error);
        //     if (error instanceof InteractionRequiredAuthError) {
        //         localStorage.clear();
        //         sessionStorage.clear();
        //         instance.logoutRedirect();
        //     }          
        // });
        if (isNotNullAndUndefined(response) && typeof response === 'object') {
            instance['accessToken'] = response.accessToken;


            const bearer = `Bearer ${response.accessToken}`;
            config.headers.Authorization = bearer;
            config.headers[RAFHeaderNames.BusinessUnitUID] = instance['currentBusinessUnitId'];
            config.headers[RAFHeaderNames.Domain] = getPureSubDomainOrHint();
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            return Promise.reject(error);
        }
    );
    /* eslint-enable no-param-reassign */

    return (
        <>
            {children}
        </>
    );
};

export default RequestInterceptor;