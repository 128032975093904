import { isNotNullAndUndefined, IsNullOrWhiteSpace } from "../../../RAFComponents/helpers/utils";
import { getSessionStorage, setSessionStorage } from "../../helpers/AppHelper";

export class ClientTerminologyJM {
    Key?: string;
    Value?: string;
}

export function getClientTerminologiesFromStorage(storageKey: string): ClientTerminologyJM[] {
    let retVal: ClientTerminologyJM[] = [];
    const clientTermsStorage = getSessionStorage(storageKey, true);
    if (isNotNullAndUndefined(clientTermsStorage) && !IsNullOrWhiteSpace(clientTermsStorage)) {
        retVal = JSON.parse(clientTermsStorage);
    }
    else {
        return null;
    }
    return retVal;
}

export function setClientTerminologiesInStorage(storageKey: string, clientTerms?: ClientTerminologyJM[]) {
    setSessionStorage(storageKey, true, JSON.stringify(clientTerms));
}

export function GetClientTerm(clientTerms: ClientTerminologyJM[], key: string, defaultValue: string): string {
    let retVal = defaultValue;
    if (isNotNullAndUndefined(clientTerms)) {
        const objTerm: ClientTerminologyJM = clientTerms.find(x => x.Key === key);
        if (isNotNullAndUndefined(objTerm)) {
            retVal = objTerm.Value;
        }
    }
    return retVal;
}